new Swiper('.news-swiper', {
  navigation: {
    prevEl: '.news-swiper-btn-prev',
    nextEl: '.news-swiper-btn-next',
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 16,
  speed: 1000,
  freeMode: true,
  loop: true,
  // autoplay: {
  //   delay: 8000,
  //   disableOnInteraction: false
  // },
  // breakpoints: {
  //   1440: {
  //     slidesPerView: 1.4,
  
  //   },
  //   1440: {
  //     slidesPerView: 1.75,
  //   },
  // },
});
