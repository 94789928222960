import '/src/common/js/onLoad';
import '/src/components/mobile-menu/mobile-menu';

import '/src/pages/home/section/hero/hero-swiper';
import '/src/pages/home/section/gallery/gallery-swiper';
import '/src/pages/home/section/news/news-swiper';
import '/src/components/counter/counter';

// ========== for page 3 / 4 / 16 / 31 / 33 ==========
const feedbackForm = document.forms?.feedbackForm;
feedbackForm?.addEventListener("change", onInputFileChange)
feedbackForm?.addEventListener("click", onInputFileBtnDeleteClick)

function onInputFileChange(e) {
  if (!e.target.classList.contains('file__input')) return

  const currentLabelWrapRef = e.target.closest('.file__label-wrap');
  const currentFileChosenRef = currentLabelWrapRef?.querySelector('.file__chosen');
  const currentFileSizeRef = currentLabelWrapRef?.querySelector('.file__size');
  const currentLabelRef = currentLabelWrapRef.querySelector('label');
  const currentFileName = e.target?.value?.replace(/.*\\/, "");

  currentLabelWrapRef.classList.add('active');
  currentFileChosenRef.innerText = currentFileName;
  currentFileSizeRef.innerText = returnFileSize(e.target?.files[0]?.size);
  currentLabelRef.style.color="#B72025"
}

function returnFileSize(number) {
  if (number < 1024) {
    return `${number} bytes`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(2)} KB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(2)} MB`;
  }
}

function onInputFileBtnDeleteClick(e) {
  if (!e.target.classList.contains('file__btn--delete')) return 
 
  const currentLabelWrapRef = e.target.closest('.file__label-wrap');
  const currentFileChosenRef = currentLabelWrapRef?.querySelector('.file__chosen');
  const currentFileSizeRef = currentLabelWrapRef?.querySelector('.file__size');
  const currentLabelRef = currentLabelWrapRef.querySelector('label');
  const currentInputRef = currentLabelWrapRef.querySelector('input');

  currentLabelWrapRef.classList.remove('active');
  currentFileChosenRef.innerText = "";
  currentFileSizeRef.innerText = ""
  currentLabelRef.style.color = null;
  currentInputRef.value = null;
}
// ========== for page 3 / 4 / 16 / 31 / 33 ==========
// ========== for page 5 / 30 ==========
const accordionListRef = document.querySelector('.accordion__list');
accordionListRef?.addEventListener("click", onAccordionClick);

function onAccordionClick(e) {
  if (!e.target.classList.contains('accordion__btn')) return

  const element = e.target?.closest('.accordion');
  const elementExtraContent = element?.querySelector('.accordion__box');
  const elementBtn = element?.querySelector('.accordion__btn');

  if (elementExtraContent.style.maxHeight) elementExtraContent.style.maxHeight = null;
  else elementExtraContent.style.maxHeight = elementExtraContent.scrollHeight + 'px';

  const expanded = elementBtn.getAttribute('aria-expanded') === 'true' || false;
  elementBtn.setAttribute('aria-expanded', !expanded);
  elementBtn?.classList.toggle('isActive');
}
// ========== for page 5 / 30 ==========
// ========== for page 9 / 10 ==========
import '/src/pages/9/products-swiper';

const optionsTabsBtnsListRef = document.querySelector( '.options-tabs-btns__list');
optionsTabsBtnsListRef?.addEventListener('click', onOptionsTabsBtnClick);

function onOptionsTabsBtnClick(e) {
  if (!e.target.classList.contains('options-tabs-btn')) return;

  const tabBtnList = document.querySelectorAll(`.options-tabs-btn`);
  const tabList = document.querySelectorAll(`.options-tabs__item`);

  tabBtnList.forEach(i => { i.classList.remove('active')});
  tabList.forEach(i => { i.classList.remove('active')});

  e.target.classList.add('active');
  document.querySelector(`[data-tab="${e.target.dataset.tab_btn}"]`)?.classList.add('active');
}
// ========== for page 9 / 10 ==========
// ========== for page 23 ==========
new Swiper('.gallery-23-swiper', {
  navigation: {
    prevEl: '.gallery-23-swiper-btn-prev',
    nextEl: '.gallery-23-swiper-btn-next',
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 24,
  speed: 1000,
  freeMode: true,
  loop: true,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
  },
});
// ========== for page 23 ==========