import { hideElement, showElement } from "/src/common/js"; 

const burgerMenuBtnOpenRef = document.querySelector('.mobile-menu__btn--open');
const burgerMenuBtnClosenRef = document.querySelector('.mobile-menu__btn--close');
const burgerMenuRef = document.querySelector('.mobile-menu');

burgerMenuBtnOpenRef.addEventListener('click', toggleBurgerMenu);
burgerMenuBtnClosenRef.addEventListener('click', toggleBurgerMenu);
burgerMenuRef.addEventListener('click', onBurgerMenuClick);

function toggleBurgerMenu() {
  const expanded = burgerMenuBtnOpenRef.getAttribute('aria-expanded') === 'true' || false;

  if (expanded) hideBurgerMenu();
  else showBurgerMenu();
}
function hideBurgerMenu() {
  hideElement(burgerMenuRef);
  burgerMenuBtnOpenRef.setAttribute('aria-expanded', false);
  burgerMenuBtnClosenRef.setAttribute('aria-expanded', false);
  document.body.classList.remove('mobileMenuIsOpen');
}
function showBurgerMenu() {
  showElement(burgerMenuRef);
  burgerMenuBtnOpenRef.setAttribute('aria-expanded', true);
  burgerMenuBtnClosenRef.setAttribute('aria-expanded', true);
  document.body.classList.add('mobileMenuIsOpen');
}
function onBurgerMenuClick(e) {
  if (e.target.classList.contains('mobile-menu-box__btn')) {
    const element = e.target?.closest('.mobile-menu-box__item');
    const elementExtraContent = element?.querySelector('.mobile-menu-box');
    const elementBtn = element?.querySelector('.mobile-menu-box__btn');

    if (elementExtraContent.style.maxHeight) elementExtraContent.style.maxHeight = null;
    else elementExtraContent.style.maxHeight = elementExtraContent.scrollHeight + 'px';

    const expanded = elementBtn.getAttribute('aria-expanded') === 'true' || false;
    elementBtn.setAttribute('aria-expanded', !expanded);
    elementBtn?.classList.toggle('isActive');
  }
}


// function offset(el) {// Корректно и кроссбраузерно выщитывает позиция объекта относительно верха
//   const rect = el.getBoundingClientRect(),
//     scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
//     scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//   return {top: rect.top + scrollTop, left: rect.left + scrollLeft }
// }



// updateSize();
// window.addEventListener("resize", updateSize);
// function updateSize() {
//   const innerWidth = window.innerWidth;
 
//   if (innerWidth < 768) hideBurgerMenu()
//   else showBurgerMenu();
// }

// function checkBtn(e) {
//   if (!e.target.classList.contains('mobile-menu-box__btn')) return;
 
//   const href = e.target.getAttribute('href');
//   const currentTargetEl = document.querySelector(`${href}`);
//   const currentTargetElTop = offset(currentTargetEl).top
 
//   windowScrollTo(currentTargetElTop);
//   toggleBurgerMenu();
// }

// articlesListRef.addEventListener('click', onListClick);
