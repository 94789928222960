new Swiper('.products-swiper', {
  navigation: {
    prevEl: '.products-swiper-btn-prev',
    nextEl: '.products-swiper-btn-next',
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 40,
  speed: 1000,
  freeMode: true,
  // loop: true,
  // autoplay: {
  //   delay: 8000,
  //   disableOnInteraction: false
  // },
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    1440: {
      slidesPerView: 3,
    },
  },
});

