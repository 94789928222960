const counterRef = document.querySelector('.counter');

counterRef?.addEventListener('click', onCounterBtnClick)

function onCounterBtnClick(e) {
  if (!e.target.classList.contains('counter__btn')) return

  const counterValueRef = document.querySelector('.counter__value');
  const counterValue = Number( counterValueRef?.innerText);
  let newValue = counterValue ? counterValue : 1;
  
  switch (e?.target?.dataset?.type) {
    case "decrement":
      newValue === 1 ? newValue = 1 : newValue -=1;
      break;
    case "increment":
      newValue +=1
      break;
  
    default:
      break;
  }

  counterValueRef.innerText = newValue
}