new Swiper('.hero-swiper', {
  navigation: {
    prevEl: '.hero-swiper-btn-prev',
    nextEl: '.hero-swiper-btn-next',
  },
  pagination: {
    el: '.hero-swiper-pagination',
    clickable: true,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  speed: 1000,
  freeMode: true,
  loop: true,
  autoplay: {
    delay: 10000,
    disableOnInteraction: false
  },
});
